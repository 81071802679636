import React from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import Layout from "../../components/mschool/layout";
import SEO from "../../components/seo";
import SchedulePopup from "../../components/schedulepopup";
import ProInstructors from "../../components/mschool/proinstructors";
import useWindowSize from "../../hooks/useWindowSize";
import TopBanner from "../../components/mschool/topbanner";
import settings from "../../../settings";
import ThermalCaliforniaBanner from "../../components/mschool/thermalcaliforniabanner";

const banner =
	`${settings.IMAGES_BASE_URL}/images/race-license/race-license-banner.jpg`;
const bannerMobile =
	`${settings.IMAGES_BASE_URL}/images/race-license/race-license-banner.jpg`;

const flagFlyer =
	`${settings.IMAGES_BASE_URL}/images/race-license/flag-flyer.jpg`;
const passing =
	`${settings.IMAGES_BASE_URL}/images/race-license/passing.jpg`;
const raceStars =
	`${settings.IMAGES_BASE_URL}/images/race-license/race-starts.jpg`;
const sandwichLapping =
	`${settings.IMAGES_BASE_URL}/images/race-license/sandwich-lapping.jpg`;
const raceTalk =
	`${settings.IMAGES_BASE_URL}/images/race-license/race-talk.jpg`;

const mdriver =
	`${settings.IMAGES_BASE_URL}/images/classes/m-drivers-class.jpg`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/one-day-m-class.jpg`;
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/two-day-m-class.jpg`;
const mfour =
	`${settings.IMAGES_BASE_URL}/images/classes/m4-gt4-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/images/classes/advanced-m-school-banner%201.png`;
const indyfullday =
	`${settings.IMAGES_BASE_URL}/images/indy_images/full-day-indy-box.jpg`;

const Agenda = () => {
	return (
		<SchedulePopup
			btnText="View Race License School Agenda"
			title="RACE LICENSE SCHOOL"
			subtitle="Sample schedule subject to change."
		>
			<div className="schedule__item">
				<p className="schedule__item__time">8:15 am - 8:30 am</p>
				<p className="schedule__item__event">Arrival, registration</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">8:30 am - 9:40 am</p>
				<p className="schedule__item__event">Class discussion</p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">9:40 am - 12:00 pm</p>
				<p className="schedule__item__event">Drive time</p>
			</div>

			<div className="schedule__item">
				<p className="schedule__item__time">12:30 pm - 1:00 pm</p>
				<p className="schedule__item__event">Lunch</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">1:00 pm - 1:15 pm</p>
				<p className="schedule__item__event">Class discussion</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">1:15 pm - 4:30 pm</p>
				<p className="schedule__item__event">Drive time</p>
			</div>
			<div className="schedule__item">
				<p className="schedule__item__time">4:30 pm</p>
				<p className="schedule__item__event">Closing</p>
			</div>
		</SchedulePopup>
	);
};

const RaceLicenseSchoolPage = () => {
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;

	return (
		<Layout>
			<SEO
				title="BMW M Performance Driving School | Race License School"
				description="The BMW Race License School experience. Want even more? You can get SCCA certified with this school and take your first steps to becoming a pro driver.
                You can even add an extra day to a Two-Day M School or Advanced M School session and get qualified all at once."
				keywords="bmw racelicense, bmw race license, bmw license school"
			/>
			<div className="racelicenseschool">
				<TopBanner
					image={banner}
					mobileImage={bannerMobile}
					mobileImageClassName="topbanner-image-mobile"
					header="RACE LICENSE SCHOOL"
					subheader="THE PATH TO PROFESSIONAL"
					pricing="FROM $2,495"
					text="Want even more? You can get SCCA certified with this school and take your first steps to becoming a pro driver. You can even add an extra day to a Two-Day M School or Advanced M School session and get qualified all at once. *"
					buttonText="Book in California"
					butttonURL="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Race_License_School"
					agendaPopup={<Agenda />}
					arrow
				>
					<p className="small_faq_note">
						<br className="mobile-break-line" />
						* Available at Thermal location only. Participants must call ahead
						for verification of their qualifications. Must complete Two-Day M
						School or Advanced M School within 12 months of taking this
						course.
						<br/>
						<Link to="/schoolfaq">
							For more information, please visit our FAQ page.
						</Link>
					</p>
				</TopBanner>
				<section className="learn">
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Flag Flyer">
								<LazyLoadImage
									effect="blur"
									src={flagFlyer}
									alt="M4GT4 School"
								/>
								<div className="accordion-content">
									<h3>Learn the colors</h3>
									<p>
										Green. Yellow. Red - no there aren’t any traffic lights on
										the track. But there are flags, and you’ll learn what each
										represents while you’re out there to maintain safety. This
										culminates in a final flag test, an official part of your
										SCCA application process.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Passing">
								<LazyLoadImage effect="blur" src={passing} alt="M4GT4 School" />
								<div className="accordion-content">
									<h3>Don’t let off</h3>
									<p>
										When is it safe to pass? Hint: not all the time. Here, we’ll
										break down passing technique, application and etiquette.
										Understand what appropriate passing is, and the differing
										approaches to handle a braking zone leading up to the corner
										when considering a pass.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Race Starts">
								<LazyLoadImage
									effect="blur"
									src={raceStars}
									alt="M4GT4 School"
								/>
								<div className="accordion-content">
									<h3>Practice race starts</h3>
									<p>
										In racing, how you start can impact how you finish, and
										we’ll show you how to get started right in both a rolling
										double and single file. In addition, we’ll practice the
										rules and expectations while following a pace car.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Sandwich Lapping">
								<LazyLoadImage
									effect="blur"
									src={sandwichLapping}
									alt="M4GT4 School"
								/>
								<div className="accordion-content">
									<h3>Sandwich Lapping</h3>
									<p>
										No, not lunch in the car. While M School has you follow the
										instructor through guided laps, here the instructor has you
										leading each lap while the instructor coaches on the radio
										from behind. They’ll help you understand the appropriate
										level of brake application, turn-in, apex and track out.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Race talk">
								<LazyLoadImage
									effect="blur"
									src={raceTalk}
									alt="M4GT4 School"
								/>
								<div className="accordion-content">
									<h3>Race Talk</h3>
									<p>
										We get it – your first race will be exhilarating, but
										there’s a lot to consider. Our instructors are here
										throughout the weekend to answer questions and provide
										advice as you gear up for your own racing career.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="flag-flyer" uniqueKey="1">
										<LazyLoadImage
									    effect="blur"
											src={flagFlyer}
											alt="M4GT4 School"
										/>
									</TabContent>
									<TabContent for="passing" uniqueKey="2">
										<img
											src={passing}
											alt="M4GT4 School"
										/>
									</TabContent>
									<TabContent for="race-starts" uniqueKey="3">
										<img
											src={raceStars}
											alt="M4GT4 School"
										/>
									</TabContent>
									<TabContent for="sandwich-lapping" uniqueKey="4">
										<img
											src={sandwichLapping}
											alt="M4GT4 School"
										/>
									</TabContent>
									<TabContent for="race-talk" uniqueKey="5">
										<img
											src={raceTalk}
											alt="M4GT4 School"
										/>
									</TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="flag-flyer passing race-starts sandwich-lapping race-talk" aria-busy="true">
									<li role="presentation">
										<TabLink role="tab" id="flag-flyer" to="flag-flyer" uniqueKey="6">Flag Flyer</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="passing" to="passing" uniqueKey="7">Passing</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="race-starts" to="race-starts" uniqueKey="8">Race Starts</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="sandwich-lapping" to="sandwich-lapping" uniqueKey="9">Sandwich Lapping</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="race-talk" to="race-talk" uniqueKey="10">Race talk</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="flag-flyer" uniqueKey="11">
										<h3>Learn the colors</h3>
										<p>
											Green. Yellow. Red - no there aren’t any traffic lights on
											the track. But there are flags, and you’ll learn what each
											represents while you’re out there to maintain safety. This
											culminates in a final flag test, an official part of your
											SCCA application process.
										</p>
									</TabContent>
									<TabContent for="passing" uniqueKey="12">
										<h3>Don’t Let Off</h3>
										<p>
											When is it safe to pass? Hint: not all the time. Here,
											we’ll break down passing technique, application and
											etiquette. Understand what appropriate passing is, and the
											differing approaches to handle a braking zone leading up
											to the corner when considering a pass.
										</p>
									</TabContent>
									<TabContent for="race-starts" uniqueKey="13">
										<h3>Practice race starts</h3>
										<p>
											In racing, how you start can impact how you finish, and
											we’ll show you how to get started right in both a rolling
											double and single file. In addition, we’ll practice the
											rules and expectations while following a pace car.
										</p>
									</TabContent>
									<TabContent for="sandwich-lapping" uniqueKey="14">
										<h3>Sandwich Lapping</h3>
										<p>
											No, not lunch in the car. While M School has you follow
											the instructor through guided laps, here the instructor
											has you leading each lap while the instructor coaches on
											the radio from behind. They’ll help you understand the
											appropriate level of brake application, turn-in, apex and
											track out.
										</p>
									</TabContent>
									<TabContent for="race-talk" uniqueKey="15">
										<h3>Race Talk</h3>
										<p>
											We get it – your first race will be exhilarating, but
											there’s a lot to consider. Our instructors are here
											throughout the weekend to answer questions and provide
											advice as you gear up for your own racing career.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<ThermalCaliforniaBanner />
				<ProInstructors image={`${settings.IMAGES_BASE_URL}/images/m4-gt4/bmw-pro-instructors-banner-m4-gt4.jpg`} />
				<section className="other-schools">
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt="One Day M School"
							/>
							<h3>One Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage effect="blur" src={mfour} alt="M4 GT4 School" />
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={mdriver}
								alt="M Driver’s Program"
							/>
							<h3>M Driver’s Program</h3>
							<p>Take yourself to the limit.</p>
							<Link to="/mschool/mdriver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default RaceLicenseSchoolPage;
